[data-theme="dark"] .byma-help-box {
  background-color: transparent !important;
  border: none;

  .card-header {
    //background-color: #444444;
    background-color: transparent !important;;
    border: none;
    font-family: var(--font-family-light);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .card-body {
    //background-color: #444444;
    background-color: transparent !important;;
    border: none;
    font-family: var(--font-family-light);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
}
