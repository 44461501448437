.bymaTextField {
  label {
    color: white;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  .inputInnerAddon input[type="password"] {
    -webkit-text-security: square;
  }

  .inputFormField {
    color: white;
    border: 1px solid var(--byma-gray-2);
    border-radius: 20px;
  }

  .inputFormField:focus {
    outline: none !important;
    border: 1px solid #1A73E8 !important;
  }

  .inputLeftIcon {
    padding-left: 40px;
  }

  .input-NoLeftIcon {
    padding-left: 10px;
  }

  .inputInnerAddon {
    position: relative;
    height: inherit;

    input {
      width: 100%;
      height: 40px;
    }

    /* align icon */
    .inputLeftAddon {
      position: absolute;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 20px;
      pointer-events: none;
      left: 15px;
    }

    .inputRightAddon {
      position: absolute;
      float: right;
      right: 15px;
      margin-top: 10px;
      height: 18.7px;
      width: 20.9px;
    }
  }
}

.input-form-field-error {
  border: 1px solid red !important;
}
