// Typographies
@mixin h2 {
  font-family: var(--font-montserrat-light);
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
}
@mixin h4 {
  font-family: var(--font-family-light);
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}
@mixin h5 {
  font-family: var(--font-family-light);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.login {
  background: linear-gradient(
      228deg,
      var(--transparent-black-4) 44%,
      var(--transparent-black-2) 82%
    ),
    linear-gradient(
      256deg,
      rgba(28, 27, 31, 1) 54%,
      var(--transparent-black-2) 79%,
      var(--transparent-black-3) 96%
    ),
    url("https://static.overlay-tech.com/assets/99b83792-516c-452c-85e2-e76548d67097.png"),
    rgba(0, 58, 112, 1);

  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;

  .logo {
    padding-left: 4%;
    padding-top: 3%;
    float: left;
  }

  .form {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    float: right;

    .loginHeader {
      padding-bottom: 2%;

      .title {
        width: 450px;
        height: 30px;
        font-style: normal;
        line-height: 30px;
        color: var(--primary-color-normal);
        margin: 0;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        @include h2;
      }

      .subtitle {
        width: 450px;
        height: 24px;
        color: var(--byma-blanco);
        right: -10px;
        bottom: 0;
        @include h5;
      }
    }

    .formInput {
      font-family: var(--font-family-light);
      width: 380px;
    }

    .submitButton {
      width: 345px;
      height: 40px;
    }

    .submitButtonGroup {
      padding-top: 5%;
    }

    .recoveryQuestion {
      width: 380px;
      align-self: end;
      padding-top: 4%;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-family: var(--font-family-light);
      color: var(--byma-primary-2);
      text-decoration: none;
      cursor: pointer;
      @include h5;
    }

    .loginErrorAlert {
      width: 345px;
      height: 55px;
      padding-top: 7px;
      height: 70px;
    }

    .loginRecoveryAlert {
      width: 345px;
      padding-top: 3%;
      height: 70px;
    }

    .loginErrorAlert p {
      width: 85%;
    }

    .loginErrorAlert p,
    .loginRecoveryAlert p {
      font-size: 14px !important;
      font-weight: 400;
      line-height: 24px;
      font-family: var(--font-family-light) !important;
      text-decoration: none;
      cursor: pointer;
      @include h5;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.logoImage {
  width: 266.5px;
  height: 116.7px;
  margin-right: 15px;
}
