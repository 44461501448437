[data-theme="dark"] .app-navbar {
  max-height: 60px !important;
  padding-left: 2%;
  padding-right: 1%;
  height: 50px !important;
  background: var(--primary-gray-dark);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .navbar-logo img {
    width: 180px;
    height: 27.12px;
  }

  .app-navbar-items {

    /* Por ahora queda asi hasta que se defina bien lo del tema */
    .nav-link {
      color: rgba(255, 255, 255, 0.4) !important;
      min-width: 140px;
      text-align: center;
      margin-left: 2%;
      margin-right: 2%;
    }

    .nav-link::after {
      display: none;
    }

    // Esto es para cuando tengamos lso botones de la barra
    /*.active {
      color: #ffffff !important;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 18px;
      height: 35px;
    }*/
    .active {
      background-color: transparent;
    }
  }

  .config-dropdown {
    .dropdown-menu {
      margin-top: 10%;
      background: var(--byma-button-gray);
      border-color: var(--byma-button-gray);
      padding: 12px 16px 12px 16px;

      a {
        color: white !important;
        padding-top: 0px;
        padding-bottom: 3px;
        padding-left: 0px;
      }

      a:hover {
        background: var(--byma-button-gray);
      }

      .dropdown-item {
        .navbar-dropdown-icon {
          padding-right: 10%;
        }

        span {
          padding-left: 0%;
        }
      }

      .user-label {
        margin-left: 4px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .right-buttons {
    .config-dropdown > .nav-link::after {
      display: none;
    }
    .dropdown-menu {
      margin-top: 35% !important;
      left: -145px;
      width: 188px;
      //height: 165px;
    }

    .navbar-button,
    .config-dropdown > .nav-link {
      color: rgba(0, 0, 0, 0.65);
      text-align: center;

      // Figma
      background:  var(--gray-color-dark);

      border-radius: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px;
      gap: 10px;
      width: 27px;
      height: 27px;
    }

    .navbar-button,
    .nav-link:first-of-type {
      margin-left: 16px;
    }

    .active {
      background-color: transparent;
    }

    .dropdown-item:hover {
      background-color: transparent;
    }

    .navbar-button-icon {
      width: 95%;
    }
  }
}
