.byma-title {
  display: flex;
  border-bottom: 0.25px solid #ced4da;
  height: 30px;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  padding-bottom: 5px !important;
  width: 97% !important;

  i {
    padding-top: 0.5%;
    padding-left: 0.5%;
  }

  p {
    margin-left: 1.5%;
    font: 'Montserrat';
    font-weight: 300px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 5%;
  }
}
