[data-theme="dark"] .byma-card-container {
  //background: var(--secondary-gray-normal) !important;
  //border-radius: 20px;
  //padding: 1%;

  .byma-card-header {
    position: relative;
    margin-bottom: -1%;
    background: #282c34;
    border-radius: 50px;
    text-align: center;
    margin-left: 3%;
    width: 200px;
  }

  .byma-card {
    height: 100%;
    width: 100%;
    position: static;

    background: var(--secondary-gray-normal) !important;
    border-radius: 20px;
    //border: 1px solid #1a73e8 !important;
    border: none;
    padding: 1%;

    .form-input {
      width: 90%;
    }

    .byma-card-body {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .byma-card-footer {
      background: transparent !important;
      border: none;

      div {
        float: right;
        padding-left: 25px;
      }
    }
  }
}
