.byma-pagination {
  background-color: transparent;
  padding-top: 20px;
  width: 200px;
  margin: 0 auto;

  .page-item > .page-link {
    background-color: transparent;
    color: var(--byma-gray-2);
    border: none;
  }

  .page-item:hover {
    border: yellow;
  }

  .page-item.disabled {
    .page-link {
      background-color: transparent;
    }
  }

  .page-item.active {
    .page-link {
      border-radius: 20px;
      background: var(--byma-button-gray);
    }
  }
}
