.filtersForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
  
}

.filtersButton {
  float: right;
  margin-bottom: 20px;
  cursor: pointer;
}

.filterFormInput {
  width: 130px;
}
