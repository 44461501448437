.selectFieldInputSingleValue {
  color: white !important;
}

.selectFieldInputMultiValue {
  border-radius: 20px !important;
  background-color: #1a73e8 !important;
}

.selectFieldControl {
  color: white;
  border: 1px solid #ced4da;
  border-radius: 20px;
  --bs-bg-opacity: 1;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.selectFieldControlFocus {
  outline: none !important;
  border: 1px solid #1a73e8 !important;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.selectFieldMenu {
  outline: none !important;
  border: 1px solid #4e5261 !important;
  margin-top: 4px;
  width: 98%;
  border-radius: 10px;
  padding-bottom: 2%;
  padding-top: 2%;
  --bs-bg-opacity: 1;
  background-color: var(--secondary-gray-normal) !important;
}

.selectFieldMenuOption {
  --bs-bg-opacity: 1;
  //background-color: transparent !important;
  background-color: var(--secondary-gray-normal) !important;
}

.selectFieldMenuOption:hover {
  --bs-bg-opacity: 1;
  //background-color: transparent !important;
  background-color: var(--secondary-gray-light) !important;
}

.selectFieldMenuList {
  --bs-bg-opacity: 1;
  background-color: var(--secondary-gray-normal) !important;
}

.selectFieldMenuList::-webkit-scrollbar {
  width: 7px !important;
  height: 0px !important;
}
.selectFieldMenuList::-webkit-scrollbar-track {
  background: var(--secondary-gray-normal) !important;
  border-radius: 50px !important;
}
.selectFieldMenuList::-webkit-scrollbar-thumb {
  background: #262a3d !important;
  border-radius: 50px !important;
}
.selectFieldMenuList::-webkit-scrollbar-thumb:hover {
  background: #262a3d !important;
}

.selectFormGroup {
  width: 90%;
  height: inherit;
  .bymaSelect {
    label {
      //color: black;
      padding-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.form-field-error {
  border: 1px solid red !important;
}
