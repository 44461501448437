.navigateGroupButton {
  .byma-button {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
    border: none;
    margin-left: 30px;
  }

  .byma-button:hover {
    background-color: transparent !important;
    border: none;
  }
}

.button-group {
  .button-left-addon {
    position: absolute;
    padding-top: 10px;
    padding-left: 18px;
  }
}
