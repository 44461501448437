@import url("https://unpkg.com/bootstrap-darkmode@5.0.1/css/darktheme.css");
@import url("https://coliff.github.io/bootstrap-show-password-toggle/css/show-password-toggle.css");
@import url("https://raw.githubusercontent.com/davidstutz/bootstrap-multiselect/master/dist/css/bootstrap-multiselect.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Inter");

:root {
  --font-family-regular: "Inter";
  --font-roboto-regular: "Roboto-regular";
  --font-family-bold: "Inter-bold";
  --font-family-light: "Inter";
  --font-family-extra-light: "Extra-light";
  --font-montserrat-light: "Montserrat";
  --primary-color-white: #FFFFFF;
  --primary-color-light: #00ACC8;
  --primary-color-normal: #0071CE;
  --byma-primary-2: #0183D9;
  --gray-color-dark: #6C757D;
  --byma-gray-2: #CED4DA;
  --error-color: #D00909;
  --border-color: #D9D9D9;
  --byma-blanco: rgba(255, 255, 255, 1);
  --transparent-black-2: rgba(0, 0, 0, 0);
  --transparent-black-3: rgba(28, 27, 31, 0);
  --transparent-black-4: rgba(28, 27, 31, 0.2);
  --byma-button-gray: #626573;
  --byma-button-blue: #0183D9;
  --byma-success: #4FAE22;
  --primary-gray-dark: #262A3D;
  --secondary-gray-normal: #3B3F50;
  --secondary-gray-light: #4E5261;
}

[data-theme="dark"] {
  color: #f8f9fa;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container-fluid {
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-container {
  .container {
    //height: 90%;
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    //background: #262A3D;
  }

  padding-left: 1% !important;
  padding-right: 1% !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Scrollbar
.select-field::-webkit-scrollbar,
select::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}
.select-field::-webkit-scrollbar-track,
select::-webkit-scrollbar-track {
  background: #4e5261;
  border-radius: 50px;
}
.select-field::-webkit-scrollbar-thumb,
select::-webkit-scrollbar-thumb {
  background: #262a3d;
  border-radius: 50px;
}
.select-field::-webkit-scrollbar-thumb:hover,
select::-webkit-scrollbar-thumb:hover {
  background: #262a3d;
}
