.exportGroupButton {
    position: absolute;

  img {
    //position: absolute;
    padding-top: 10px !important;
    padding-left: 22px !important;
  }

  button {
    //background-color: #626573 !important;
    //border: 1px solid #626573 !important;
    width: 48px;
    height: 33px;
  }
  
  //.byma-button:hover {
    //background-color: #626573 !important;
    //border: 1px solid #626573 !important;
  //}
}
