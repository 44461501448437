.byma-dual-list-container {
  .byma-dual-list-box {
  }

  .error-text {
    color: red;
    padding-left: 3%;
    height: 24px;
  }
}
