.byma-alert {
  //font-family: var(--font-family-regular) !important;

  .btn-close {
    background-size: 12px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
  }
}

.alert-dismissible {
  padding-right: 20px !important;
  padding-top: 8px !important;
}

.alert-primary {
  background: rgba(1, 131, 217, 0.1) !important;
  border-color: var(--byma-primary-2) !important;
}

.alert-danger {
  background: rgba(247, 85, 85, 0.2) !important;
  border-color: #f75555 !important;
}

.alert-info {
  background: rgba(255, 193, 7, 0.2) !important;
  border-color: #ffc107 !important;

  p {
    color: #ffc107 !important;
  }
}
