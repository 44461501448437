.byma-modal-container {
  top: 30% !important;

  .modal-content {
    background: var(--secondary-gray-normal) !important;
    border: 1px solid #0183d9;
    border-radius: 20px;

    .modal-header {
      border-bottom: none !important;
    }

    .modal-footer {
      border-top: none !important;
    }
  }
}
