.table {
    border-collapse: collapse !important; /*Para eliminar los bordes de la tabla*/
    width: 100%; /*Ancho de la tabla al 100%*/
}

.tableScrollPane {
    height: 350px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 
border-radius para agrupar datos de una colocacio

first value applies to top-left corner, 
second value applies to top-right corner, 
third value applies to bottom-right corner, 
fourth value applies to bottom-left corner 

TODO: usar sacss y variables para definir el border radius una sola vez
*/
.firstTdSingleRow {
    border-radius: 20px 0 0 20px  !important;
}

.lastTdSingleRow {
    border-radius: 0 20px 20px 0  !important;
}

.firstTdFirstRow {
    border-radius: 20px 0 0 0px  !important;
}

.lastTdFirstRow {
    border-radius: 0 20px 0 0  !important;
}

.firstTdLastRow {
    border-radius: 0 0 0 20px  !important;
}

.lastTdLastRow {
    border-radius: 0 0 20px 0  !important;
}

.singleTdLastRow {
    border-radius: 0 0 20px 20px !important;
}

.trSpacer {
    height: 10px;
}

/**
* Espacio para la ultima fila

TODO: x ahora se usa padding para el borde inferior pero no produce el efecto deseado
Sino hay que usar una fila (<tr>) extra como "spacer"

top padding
right padding 
bottom padding
left padding
*/
/*
.tdLastRow {
    padding: 10px 0 50px 10px !important;
}

.tdDefault {
    padding: 10px 0 0 10px !important;
}
*/

.tdCommon {
    border: none;
}
