.bymaAccordion {
    background: transparent;

    .accordionItem {
        width: 100%;
        background: var(--secondary-gray-light) !important;
        border: none;
        margin-bottom: 10px;
        border-radius: 20px;

        .accordionHeader {
            width: 100%;

            button {
                border-radius: 20px;
                background: var(--secondary-gray-light) !important;
            }
        }

        .accordionBody {
            width: 100%;
            border-radius: 20px;
            background: var(--secondary-gray-light) !important;
        }
    }

    .accordionItem:first-of-type,
    .accordionItem:last-of-type {
        border-radius: 20px !important;

        button.colapsed {
            border-radius: 20px !important;
        }
    }
}