.byma-page-frame {
  display: flex;
  justify-content: space-between;

  .toast {
    margin-top: 10px;
    position: absolute;
  }

  .byma-page-breadcrumb {
    height: 40px;
  }

  .byma-page-title {
    height: 40px;
  }

  /*.byma-breadcrumb {
    margin-left: 10px;
  }*/

  .byma-page-body {
  }
}
