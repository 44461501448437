.roles-edit-container {
  .roles-edit-form {
    .export-buttom-col {
      width: 100%;
      height: 100%;
      padding-top: 6%;
      padding-left: 85%;
    }

    /*.form-action-buttons {
      float: right;
      display: flex;
      column-gap: 25px;

      button {
        height: 33px;
        border-radius: 50px;
      }
    }*/
  }
}
