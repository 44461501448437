.buttonGroup {

  .bymaButton {
    overflow: hidden;
    border-radius: 20px;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
  }

  .buttonLeftAddon {
    position: absolute;
    cursor: pointer;
    padding-top: 12px;
    padding-left: 20px;
    width: 35px;
  }

  .buttonLeftIcon {
    padding-left: 40px;
  }

  .buttonNoLeftIcon {
    padding-left: 17px;
  }
}
