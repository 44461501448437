.help-container {
  .help-item {
    padding-top: 20px;
    padding-bottom: 30px;

    p {
      margin-bottom: 2px;
    }

    .help-button {
      text-transform: uppercase;
    }
  }
}
