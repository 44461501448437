		.container {
			display: flex;
			flex-direction: column;
			height: 100vh;
			//background-color: black;
		}

		.top-container {
			display: flex;
			flex-direction: row;
			flex: 1;
		}

		.panel {
			flex: 1;
			padding: 20px;
			border: 1px solid #ccc;
			margin: 10px;
		}

		.bottom-container {
			flex: 1;
			padding: 20px;
			border: 1px solid #ccc;
			margin: 10px;
		}
