.byma-badge {
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;

}

.byma-badge.bg-success {
  background: rgba(79, 174, 34, 0.2);
  color: var(--byma-success);
}
