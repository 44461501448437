.bymaDropdownButton {

    .dropdownToggle::after {
        display: none;
    }

    .dropdownToggle {
        background-color: transparent !important;
        border: 1px solid #D9D9D9 !important;
        border-radius: 20px;

        img {
            cursor: pointer !important;
        }
    }
}