.changePassContainer {

  .changePassHelpBox {
    .changePassHelpBoxUl {
      list-style: none; /* Remove default bullets */
    }

    .notChecked::before {
      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #0183d9; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      font-size: 20px;
    }

    .checked::before {
      content: "\2713"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #4fae22; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      font-size: 20px;
    }
  }
}
